<template>
  <div class="bg">
    <md-dialog :md-active.sync="recommendationPopUp" :class="{'recommendationPopUp': !myWidth, 'recommendationPopUpMobile': myWidth}">
      <md-dialog-content>
        <div class="dialogHeader">
          {{ selectedRecommendation.title }}
        </div>
        <!-- IFrame for Videos TODO: YouTube Link Cases-->
        <div class="dialogVideo" v-if="selectedRecommendation.type === 'Video' && selectedRecommendation.url.includes('youtube')">
        <iframe  style="width:600px; height:400px" :src="'https://www.youtube.com/embed/' + selectedRecommendation.url.split('=')[1]" title="Video Player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <!-- Image, if Recommendation is not a Video -->
        <div class="dialogImage"  :style="getColor()" v-else>
          <img :src="'https://media.kaiser.fyi/mindsupport/424/' + selectedRecommendation.type + 'Recommendations.png'"/>
        </div>

        <div class="dialogText">
          <b>Why is this recommended?</b><br/>
          {{selectedRecommendation.text}}
        </div>
        <div class="dialogText" v-if="selectedRecommendation.url != ''">
          <b>Link to Resource:</b><br/>
          <a :href="selectedRecommendation.url" target="_blank">{{selectedRecommendation.url}}</a>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-secondary"
          @click="(recommendationPopUp = false)"
          style="font-size: 1.3em; margin: 0 20px 20px 0"
          >Close</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="addRecommendationPopUp">
      <md-dialog-content>
        <div class="dialogHeader">Add new {{ this.type }} Recommendation</div>
        <div class="dialogText">
          <form novalidate class="md-layout">
            <md-field>
              <label for="title">Title</label>
              <md-input name="title" id="title" v-model="form.title"/>
            </md-field>
            <md-field>
              <label for="link">Link to the Resource</label>
              <md-input name="url" id="url" v-model="form.url" />
            </md-field>
            <md-field>
              <label for="text">Why is this Recommended?</label>
              <md-textarea name="text" id="text" v-model="form.text" md-autogrow/>
            </md-field>
          </form>
        </div>
      </md-dialog-content>
      <md-dialog-actions :class="{'flexColumn': myWidth, 'flexRow': !myWidth}">
        <md-button
          class="md-secondary"
          @click="(addRecommendationPopUp = false), clearForm()"
          style="font-size: 1.3em; margin: 0 20px 20px 0"
          >Close</md-button
        >
        <md-button
          class="md-primary"
          @click="submitRecommendation(form)"
          style="font-size: 1.3em; margin: 0 20px 20px 0"
          >Add Recommendation</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <div class="wrapper">
      <h1>User Recommendations</h1>

      <!-- BOOK RECOMMENDATIONS -->

      <div v-if="books.length > 0">
        <div :class="{'flexColumn': myWdith, 'flexRow':!myWidth}" class="header">
          <div>Book Recommendations</div>
          <div class="button" @click="addRecommendation('Book')">
            Add your own Recommendation
          </div>
        </div>
        <hr />
        <div class="recommendations" id="books">
          <div
            v-for="(book, i) in books"
            v-bind:key="book"
            :id="i"
            v-bind:class="{
              recommendationMobile: maxWidth,
              recommendation: !maxWidth,
            }"
            @click="selectedRecommendation = book, recommendationPopUp = true"
          >
            <div class="recommendationImageDiv" :style="getColor()" v-once>
              <img
                class="recommendationImage"
                src="https://media.kaiser.fyi/mindsupport/320/BookRecommendations.png"
              />
            </div>
            <p class="recommendationTitle">{{ book.title }}</p>
            <p class="recommendationSubtitle"><b>Why is this recommended?</b><br/>{{ book.text.split(" ").slice(0, 10).join(" ") + "..." }}</p>
            <!-- <p class="recommendationAuthor">{{ book.author.name }}</p> -->
            <!--<div class="flexRow"><p v-for="artTag in recommendation.tags" v-bind:key="artTag" class="recommendationTags">{{ artTag }}</p></div>-->
          </div>
        </div>
      </div>

      <!-- VIDEO RECOMMENDATIONS -->

      <div v-if="videos.length > 0">
        <div :class="{'flexColumn': myWdith, 'flexRow':!myWidth}" class="header">
          <div>Video Recommendations</div>
          <div class="button" @click="addRecommendation('Video')">
            Add your own Recommendation
          </div>
        </div>
        <hr />
        <div class="recommendations" id="videos">
          <div
            v-for="(video, i) in videos"
            v-bind:key="video"
            :id="i"
            v-bind:class="{
              recommendationMobile: maxWidth,
              recommendation: !maxWidth,
            }"
            @click="selectedRecommendation = video, recommendationPopUp = true"
          >
            <div class="recommendationImageDiv" :style="getColor()" v-once>
              <img
                class="recommendationImage"
                src="https://media.kaiser.fyi/mindsupport/320/VideoRecommendations.png"
              />
            </div>
            <p class="recommendationTitle">{{ video.title }}</p>
            <p class="recommendationSubtitle"><b>Why is this recommended?</b><br/>{{ video.text.split(" ").slice(0, 10).join(" ") + "..." }}</p>
            <!-- <p class="recommendationAuthor">{{ video.author.name }}</p> -->
            <!--<div class="flexRow"><p v-for="artTag in recommendation.tags" v-bind:key="artTag" class="recommendationTags">{{ artTag }}</p></div>-->
          </div>
        </div>
      </div>

      <!-- PODCAST RECOMMENDATIONS -->

      <div v-if="podcasts.length > 0">
        <div :class="{'flexColumn': myWdith, 'flexRow':!myWidth}" class="header">
          <div>Podcast Recommendations</div>
          <div class="button" @click="addRecommendation('Podcast')">
            Add your own Recommendation
          </div>
        </div>
        <hr />
        <div class="recommendations" id="podcasts">
          <div
            v-for="(podcast, i) in podcasts"
            v-bind:key="podcast"
            :id="i"
            v-bind:class="{
              recommendationMobile: maxWidth,
              recommendation: !maxWidth,
            }"
            @click="selectedRecommendation = podcast, recommendationPopUp = true"
          >
            <div class="recommendationImageDiv" :style="getColor()" v-once>
              <img
                class="recommendationImage"
                src="https://media.kaiser.fyi/mindsupport/320/PodcastRecommendations.png"
              />
            </div>
            <p class="recommendationTitle">{{ podcast.title }}</p>
            <p class="recommendationSubtitle"><b>Why is this recommended?</b><br/>{{ podcast.text.split(" ").slice(0, 10).join(" ") + "..." }}</p>
            <!-- <p class="recommendationAuthor">{{ podcast.author.name }}</p> -->
            <!--<div class="flexRow"><p v-for="artTag in recommendation.tags" v-bind:key="artTag" class="recommendationTags">{{ artTag }}</p></div>-->
          </div>
        </div>
      </div>

      <!-- PSYCHOLOGIST RECOMMENDATIONS -->

      <div v-if="psycologists.length > 0">
        <div :class="{'flexColumn': myWdith, 'flexRow':!myWidth}" class="header">
          <div>Psychologist Recommendations</div>
          <div class="button" @click="addRecommendation('Psychologist')">
            Add your own Recommendation
          </div>
        </div>
        <hr />
        <div class="recommendations" id="psycologist">
          <div
            v-for="(psycologist, i) in psycologists"
            v-bind:key="psycologist"
            :id="i"
            v-bind:class="{
              recommendationMobile: maxWidth,
              recommendation: !maxWidth,
            }"
            @click="selectedRecommendation = psychiolgist, recommendationPopUp = true"
          >
            <div class="recommendationImageDiv" :style="getColor()" v-once>
              <img
                class="recommendationImage"
                src="https://media.kaiser.fyi/mindsupport/320/PsycologistRecommendations.png"
              />
            </div>
            <p class="recommendationTitle">{{ psycologist.title }}</p>
            <p class="recommendationSubtitle"><b>Why is this recommended?</b><br/>{{ psycologist.text.split(" ").slice(0, 10).join(" ") + "..." }}</p>
            <!-- <p class="recommendationAuthor">{{ psycologist.author.name }}</p> -->
            <!--<div class="flexRow"><p v-for="artTag in recommendation.tags" v-bind:key="artTag" class="recommendationTags">{{ artTag }}</p></div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {axiosGet, axiosPost} from "@/utils/axiosWrapper";

export default {
  data() {
    return {
      user: {},
      maxWidth: window.innerWidth < 1280,
      tags: [],
      tagActive: "All",
      form: {
        title: "",
        url: "",
        text: "",
        author: "",
        type: "",
      },
      recommendationsRaw: [
        {
          title: "The Surprising Truth about your Weight ",
          author: {
            name: "Alex",
          },
          text: "It teaches you how to prioritise your health regardless of your weight. It is particularly helpful for people who suffer from disordered eating. ",
          type: "Book",
        },
        {
          title: "The F*ck it Diet",
          author: {
            name: "Alex",
          },
          text: "The book is meant for chronic dieters and shows you how to start trusting your body’s signals again.",
          type: "Video",
        },
        {
          title: "I Weigh",
          author: {
            name: "Alex",
          },
          text: "The podcast discusses a wide range of topics regarding body positivity (e.g., body image, gender, sexuality etc.) and aims to make everyone feel included. ",
          type: "Podcast",
        },
        {
          title: "Recommendation 1",
          author: {
            name: "Alex",
          },
          text: "This is a test",
          type: "Psycologist",
        },
        {
          title: "Recommendation 2",
          author: {
            name: "Alex",
          },
          text: "This is a test and i have to fill the box. This is a test and i have to fill the box. This is a test and i have to fill the box. This is a test and i have to fill the box. ",
          type: "Book",
        },
      ],
      books: [],
      videos: [],
      podcasts: [],
      psycologists: [],
      type: "",
      addRecommendationPopUp: false,
      recommendationPopUp: false,
      selectedRecommendation: {},
      myWidth: screen.height / screen.width > 1,
      userId: store.state.accessToken,
      randomColor: Math.floor(Math.random() * 16777215).toString(16),
    };
  },
  methods: {
    filterRecommendations: function () {
      this.books = this.recommendationsRaw.filter((a) =>
        a.type.includes("Book")
      );
      this.videos = this.recommendationsRaw.filter((a) =>
        a.type.includes("Video")
      );
      this.podcasts = this.recommendationsRaw.filter((a) =>
        a.type.includes("Podcast")
      );
      this.psycologists = this.recommendationsRaw.filter((a) =>
        a.type.includes("Psycologist")
      );
    },
    determineMobile() {
      this.maxWidth = window.innerWidth < 1280;
    },
    loadData() {
      return axiosGet("/users/id/" + this.userId)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.warn(error);
        });
    },
    link: function (l) {
      this.$router.push(l);
    },
    getColor() {
      var x = Math.floor(Math.random() * 360);
      return "background-color: hsl(" + x + ",100%, 30%)";
    },
    addRecommendation: function (a) {
      this.type = a;
      this.addRecommendationPopUp = true;
    },
    submitRecommendation: function (r) {
      r.author = this.user;
      r.type = this.type;
      axiosPost("/selfSupport/recommendation/", r)
      this.addRecommendationPopUp = false;
      this.clearForm();
      alert("Thanks for your recommendation, we will add it soon!")
    },
    clearForm: function () {
      this.form.title = "";
      this.form.url = "";
      this.form.text = "";
      this.form.author = "";
    },
  },
  async mounted() {
    this.recommendationsRaw = (
      await axiosGet(
        "/selfSupport/recommendation/published"
      )
    ).data;
    this.filterRecommendations();
    // this.tags = new Set([
    //   "All",
    //   ...this.recommendations
    //     .map((recommendation) => recommendation.tags)
    //     .reduce((a, b) => a.concat(b), [])
    //     .sort(),
    // ]);
    this.user = await this.loadData();
  },
  async created() {
    window.addEventListener("resize", this.determineMobile);
  },
  destroyed() {
    window.removeEventListener("resize", this.determineMobile);
  },
};
</script>

<style scoped>
.bg {
  background-color: white;
}

.wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
}

h1 {
  font-size: 6em;
  text-shadow: 0px 2px 2px #666666;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  padding-top: calc(var(--width) * (640 / 2596) / 2);
  --width: 1280px;
  width: var(--width);
  height: calc(var(--width) * (640 / 2596));
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.header {
  justify-content: space-between;
  font-family: var(--h2);
  font-size: 2rem;
  color: var(--c3);
  text-transform: uppercase;
  margin-top: 5%;
}
.header .button {
  background-color: transparent;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #ffffff;
  transition: 0.1s ease-in-out;
}
.header .button:hover {
  transform: scale(1.1);
  border: 2px solid #000000;
  border-radius: 10px;
}

.tagBar {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
}

.tagBar p {
  padding: 20px;
  margin: 0;
  text-transform: capitalize;
}

.tagBar p:hover {
  color: #1a9688;
  cursor: pointer;
  font-weight: bold;
}

.tagActive {
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: #4e84ad;
}

.recommendations {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  overflow-x: auto;
}
.recommendations::-webkit-scrollbar {
  height: 10px;
}
.recommendations::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #eeeeee;
}
.recommendations::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #04977F;
}

.recommendation {
  width: 31%;
  min-width: 31%;
  padding: 3%;
  margin: 1.16%;
  box-sizing: border-box;
  box-shadow: 10px 10px #adcad8;
  transition: all 0.2s ease-in-out;
  text-align: left;
  background-color: #eefaff;
}

.recommendationMobile {
  width: 50%;
  padding: 3%;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  text-align: left;
}

.recommendation:hover,
.recommendationMobile:hover {
  opacity: 0.8;
  transform: scale(0.98);
  cursor: pointer;
}

/* Desktop Only */
.recommendation:hover {
  box-shadow: 5px 5px 0px #82b8d3;
}

/* placeholder div for missing images */
.recommendationImageDiv {
  background-color: rgb(255, 0, 0);
  width: 100%;
}
.recommendationMobile .recommendationImageDiv {
  min-height: 100px;
}

.recommendationTitle {
  font-size: 1em;
  font-weight: bold;
}

.recommendationSubtitle {
  text-align: left;
}

.recommendationAuthor {
  letter-spacing: -0.33px;
  font-size: 0.9em;
}
.dialogHeader {
  width: 90%;
  font-size: 2rem;
  line-height: 2rem;
  font-family: var(--h2);
  font-weight: bold;
  margin: 2%;
}
.dialogText {
  width: 90%;
  margin: 0 auto;
  margin-top: 2%;
  font-size: 1rem;
}
.dialogImage {
  width: 50%;
  margin: 0 auto;
}
.dialogVideo {
  width: 75%;
  margin: 0 auto;
}
.recommendationPopUp {
  width: 60%; margin: 0 auto
}
.recommendationPopUpMobile {
  width: 100%;
}

@media only screen and (max-width: 640px) {
  h1 {
    font-size: 4em;
    line-height: 1em;
    margin-top: -1em;
    margin-bottom: 1em;
    padding-top: calc(var(--width) * (1024 / 2596) / 2);
    --width: 100vw;
    width: var(--width);
    height: calc(var(--width) * (1024 / 2596));
  }
  .tagBar p {
    padding: 10px;
  }
}
</style>